import { LoaderCircle } from 'lucide-react';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { cn } from '~/libs/utils';

export const Spinner = forwardRef<SVGSVGElement, HTMLAttributes<SVGSVGElement>>(({ className, ...props }, ref) => (
  <LoaderCircle ref={ref} data-testid='spinner' className={cn('mr-2 animate-spin', className)} {...props} />
));

Spinner.displayName = 'Spinner';
